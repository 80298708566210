





import { Component, Prop, Vue } from 'vue-property-decorator'
import { GlobalActions } from '@/shared/store/global/global.actions'
import FormBase from '@/shared/classes/form/form-base'
import Field from '@/shared/classes/form/field'
import SelectFromMap from '@/company/components/views/projects/SelectFromMap.vue'

@Component
export default class AppendMap extends Vue {
  @Prop() form!: FormBase
  @Prop() field!: Field

  openDialog(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: SelectFromMap,
      persistent: true,
      maxWidth: 650,
      meta: {
        form: this.form,
      }
    })
  }
}
