









import { Component, Prop, Vue } from 'vue-property-decorator'
import axios from 'axios'
import __ from '@/shared/helpers/__'
import FormBase from '@/shared/classes/form/form-base'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { GlobalGetters } from '@/shared/store/global/global.getters'
import PageHeader from '@/admin/components/PageHeader.vue'

enum AddressComponentType {
  municipality = 'administrative_area_level_1',
  number = 'street_number',
  street = 'route',
  city = 'locality',
  city2 = 'postal_town',
  country = 'country',
  postCode = 'postal_code',
}

@Component({
  components: { PageHeader },
  methods: { __ }
})
export default class SelectFromMap extends Vue {
  startLocation: any = { lat: 55, lng: 23 }
  coordinates: any = null
  @Prop() meta: any

  created() {
    const { street, number, post_code, city, country_uuid } = this.meta.form.data.location
    let { lat, lng } = this.meta.form.data.location
    lat = Number(lat)
    lng = Number(lng)

    if (lat && lng) {
      this.startLocation = {
        lat, lng
      }
      return
    }
    if (street || number || post_code || city || country_uuid) {
      const address = SelectFromMap.generateAddress(this.meta.form)
      let query = `address=${ address }`
      if (country_uuid) query += '&components=country:${ country_uuid }'

      SelectFromMap.request(query, this.$store.getters[GlobalGetters.getCurrentLocale])
        .then((response: any) => {
          if (! response.data.results[0]) return this.setFromBrowserGeoLocation()
          this.startLocation = {
            lat: response.data.results[0].geometry.location.lat,
            lng: response.data.results[0].geometry.location.lng,
          }
          this.injectFormCoords(this.startLocation.lat, this.startLocation.lng)
        })
      return
    }

    this.setFromBrowserGeoLocation()
  }

  save(): void {
    const form: FormBase = this.meta.form

    if (! this.coordinates) {
      this.$store.dispatch(GlobalActions.closeDialog)
      return
    }

    const { lat, lng } = this.coordinates

    SelectFromMap.request(`latlng=${ lat },${ lng }`, this.$store.getters[GlobalGetters.getCurrentLocale])
      .then(response => {
        this.injectFormCoords(lat, lng)
        form.data.location.number = ''
        form.data.location.street = ''
        form.data.location.post_code = ''
        form.data.location.city = ''
        form.data.location.country_uuid = ''
        form.data.location.municipality = ''

        const result = response.data.results[0]
        result.address_components.forEach((component: any) => {
          if (component.types.includes(AddressComponentType.number)) {
            form.data.location.number = component.long_name
            return
          }
          if (component.types.includes(AddressComponentType.street)) {
            form.data.location.street = component.long_name
            return
          }
          if (component.types.includes(AddressComponentType.postCode)) {
            form.data.location.post_code = component.long_name
            return
          }
          if (component.types.includes(AddressComponentType.city) || component.types.includes(AddressComponentType.city2)) {
            form.data.location.city = component.long_name
            return
          }
          if (component.types.includes(AddressComponentType.municipality)) {
            form.data.location.municipality = component.long_name
            return
          }
          if (component.types.includes(AddressComponentType.country)) {
            form.data.location.country_uuid = component.short_name.toLowerCase()
            return
          }
        })

        this.$store.dispatch(GlobalActions.closeDialog)
      })
  }

  private injectFormCoords(lat: number, lng: number): void {
    this.meta.form.data = {
      ...this.meta.form.data,
      location: {
        ...this.meta.form.data.location,
        lat,
        lng,
      }
    }
  }

  updateCoordinates(location: any) {
    this.coordinates = {
      lat: location.latLng.lat(),
      lng: location.latLng.lng()
    }
  }

  setMarkerPositionOnClick(event: any) {
    this.startLocation.lat = event.latLng.lat()
    this.startLocation.lng = event.latLng.lng()
    this.coordinates = this.startLocation
  }

  public static request(query: string, language: string): Promise<any> {
    return axios.get(`https://maps.googleapis.com/maps/api/geocode/json?key=${ process.env.VUE_APP_GOOGLE_API_KEY }&language=${ language }&${ query }`)
  }

  public static generateAddress(form: FormBase): string {
    const { street, number, post_code, city } = form.data.location
    return `${ street } ${ number } ${ city } ${ post_code }`
  }

  private setFromBrowserGeoLocation() {
    navigator.geolocation.getCurrentPosition(position => {
      this.startLocation.lat = position.coords.latitude
      this.startLocation.lng = position.coords.longitude
      this.coordinates = this.startLocation
    })
  }
}
